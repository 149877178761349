import React, { useState, useEffect } from 'react';
import * as math from 'mathjs';
import MatrixInput from './MatrixInput';
import './MatrixInverse.css';

function MatrixInverse() {
  const [size, setSize] = useState(2);
  const [matrix, setMatrix] = useState(() => Array(size).fill().map(() => Array(size).fill('')));
  const [resultInverse, setResultInverse] = useState([]);

  useEffect(() => {
    setMatrix(prevMatrix => {
      const newMatrix = Array(size).fill().map(() => Array(size).fill(''));
      for (let i = 0; i < Math.min(prevMatrix.length, size); i++) {
        for (let j = 0; j < Math.min(prevMatrix[i].length, size); j++) {
          newMatrix[i][j] = prevMatrix[i][j];
        }
      }
      return newMatrix;
    });
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 2 : Math.min(10, Math.max(2, parseInt(value)));
      setSize(numValue);
    }
  };

  const calculateInverse = () => {
    try {
      const mathMatrix = math.matrix(matrix.map(row => row.map(val => parseFloat(val) || 0)));
      const inverse = math.inv(mathMatrix);
      const roundedInverse = math.round(inverse, 6);
      setResultInverse(roundedInverse.toArray());
    } catch (error) {
      setResultInverse([]);
      alert("Matrix is not invertible or an error occurred: " + error.message);
    }
  };

  const ResultMatrix = ({ matrix }) => (
    <div className="matrix-wrapper">
      {matrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              key={j}
              type="text"
              value={typeof value === 'number' ? value.toFixed(6) : value}
              readOnly
              className="matrix-cell result-input"
            />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="matrix-inverse">
      <h2>Matrix Inverse Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size}
            cols={size}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="size-input"
        />
      </div>
      <button onClick={calculateInverse}>Calculate Inverse</button>
      {resultInverse.length > 0 && (
        <div className="result-container">
          <h2>Result</h2>
          <div className="matrix-container">
            <div className="matrix-wrapper">
              <h3>Inverse Matrix</h3>
              <ResultMatrix matrix={resultInverse} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MatrixInverse;
