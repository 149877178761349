import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import MatrixMultiplication from './MatrixMultiplication';
import MatrixAddSub from './MatrixAddSub';
import MatrixTranspose from './MatrixTranspose';
import MatrixInverse from './MatrixInverse';
import MatrixDeterminant from './MatrixDeterminant';
import MatrixRank from './MatrixRank';
import LUDecomposition from './LUDecomposition';
import QRFactorization from './QRFactorization'; // Make sure this line is present
import TopMenu from './TopMenu';
import EigenvalueCalculator from './EigenvalueCalculator'; // Add this line
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>MathSucks.lol - Matrix Calculators</title>
          <meta name="description" content="Free online matrix calculators for various operations including multiplication, addition, subtraction, inverse, determinant, and more." />
        </Helmet>
        <TopMenu />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/matrix-multiplication" element={<MatrixMultiplication />} />
            <Route path="/matrix-add-subtract" element={<MatrixAddSub />} />
            <Route path="/matrix-transpose" element={<MatrixTranspose />} />
            <Route path="/matrix-inverse" element={<MatrixInverse />} />
            <Route path="/matrix-determinant" element={<MatrixDeterminant />} />
            <Route path="/matrix-rank" element={<MatrixRank />} />
            <Route path="/lu-decomposition" element={<LUDecomposition />} />
            <Route path="/qr-factorization" element={<QRFactorization />} />
            <Route path="/eigenvalue-calculator" element={<EigenvalueCalculator />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
