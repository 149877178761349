import React, { useState, useEffect } from 'react';
import MatrixInput from './MatrixInput';
import { addMatrices, subtractMatrices } from './matrixOperations';
import './MatrixAddSub.css';

function MatrixAddSubtract() {
  const [size, setSize] = useState({ rows: 2, cols: 2 });
  const [matrixA, setMatrixA] = useState(() => Array(size.rows).fill().map(() => Array(size.cols).fill('')));
  const [matrixB, setMatrixB] = useState(() => Array(size.rows).fill().map(() => Array(size.cols).fill('')));
  const [result, setResult] = useState([]);
  const [operation, setOperation] = useState('add'); // 'add' or 'subtract'

  const resizeMatrix = (matrix, rows, cols) => {
    const newMatrix = Array(rows).fill().map(() => Array(cols).fill(''));
    for (let i = 0; i < Math.min(matrix.length, rows); i++) {
      for (let j = 0; j < Math.min(matrix[i].length, cols); j++) {
        newMatrix[i][j] = matrix[i][j];
      }
    }
    return newMatrix;
  };

  useEffect(() => {
    setMatrixA(prevMatrix => resizeMatrix(prevMatrix, size.rows, size.cols));
    setMatrixB(prevMatrix => resizeMatrix(prevMatrix, size.rows, size.cols));
  }, [size]);

  const handleMatrixChange = (setter) => (e, i, j) => {
    const value = e.target.value;
    setter(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (dimension) => (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 1 : Math.min(10, Math.max(1, parseInt(value)));
      setSize(prev => ({ ...prev, [dimension]: numValue }));
    }
  };

  const calculateResult = () => {
    const numericA = matrixA.map(row => row.map(val => parseFloat(val) || 0));
    const numericB = matrixB.map(row => row.map(val => parseFloat(val) || 0));
    try {
      let resultMatrix;
      if (operation === 'add') {
        resultMatrix = addMatrices(numericA, numericB);
      } else {
        resultMatrix = subtractMatrices(numericA, numericB);
      }
      // Round the result to 3 decimal places
      const roundedResult = resultMatrix.map(row => row.map(val => Number(val.toFixed(3))));
      setResult(roundedResult);
    } catch (error) {
      alert(error.message);
    }
  };

  const ResultMatrix = ({ matrix }) => (
    <div className="matrix-wrapper">
      {matrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              key={j}
              type="text"
              value={typeof value === 'number' ? value.toFixed(3) : value}
              readOnly
              className="matrix-cell result-input"
            />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="matrix-add-subtract">
      <h2>Matrix Addition/Subtraction Calculator</h2>
      <div className="operation-toggle">
        <label>
          <input
            type="radio"
            value="add"
            checked={operation === 'add'}
            onChange={() => setOperation('add')}
          />
          Addition
        </label>
        <label>
          <input
            type="radio"
            value="subtract"
            checked={operation === 'subtract'}
            onChange={() => setOperation('subtract')}
          />
          Subtraction
        </label>
      </div>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size.rows}
            cols={size.cols}
            matrix={matrixA}
            onChange={handleMatrixChange(setMatrixA)}
          />
        </div>
        <div className="operation">{operation === 'add' ? '+' : '-'}</div>
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size.rows}
            cols={size.cols}
            matrix={matrixB}
            onChange={handleMatrixChange(setMatrixB)}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size.rows}
          onChange={handleSizeChange('rows')}
          className="size-input"
        />
        <span>×</span>
        <input
          type="text"
          value={size.cols}
          onChange={handleSizeChange('cols')}
          className="size-input"
        />
      </div>
      <button onClick={calculateResult}>Calculate</button>
      {result.length > 0 && (
        <div className="result-container">
          <h2>Result</h2>
          <ResultMatrix matrix={result} />
        </div>
      )}
    </div>
  );
}

export default MatrixAddSubtract;
