import React, { useState, useEffect } from 'react';
import MatrixInput from './MatrixInput';
import { multiplyMatrices } from './matrixOperations';

function MatrixMultiplication() {
  const [sizeA, setSizeA] = useState({ rows: 2, cols: 2 });
  const [sizeB, setSizeB] = useState({ rows: 2, cols: 2 });
  const [matrixA, setMatrixA] = useState(() => Array(sizeA.rows).fill().map(() => Array(sizeA.cols).fill('')));
  const [matrixB, setMatrixB] = useState(() => Array(sizeB.rows).fill().map(() => Array(sizeB.cols).fill('')));
  const [result, setResult] = useState([]);

  const resizeMatrix = (matrix, rows, cols) => {
    const newMatrix = Array(rows).fill().map(() => Array(cols).fill(''));
    for (let i = 0; i < Math.min(matrix.length, rows); i++) {
      for (let j = 0; j < Math.min(matrix[i].length, cols); j++) {
        newMatrix[i][j] = matrix[i][j];
      }
    }
    return newMatrix;
  };

  useEffect(() => {
    setMatrixA(prevMatrix => resizeMatrix(prevMatrix, sizeA.rows, sizeA.cols));
  }, [sizeA]);

  useEffect(() => {
    setMatrixB(prevMatrix => resizeMatrix(prevMatrix, sizeB.rows, sizeB.cols));
  }, [sizeB]);

  const handleMatrixChange = (setter) => (e, i, j) => {
    const value = e.target.value;
    setter(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (setter, dimension) => (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 1 : Math.min(10, Math.max(1, parseInt(value)));
      setter(prev => ({ ...prev, [dimension]: numValue }));
    }
  };

  const calculateResult = () => {
    const numericA = matrixA.map(row => row.map(val => parseFloat(val) || 0));
    const numericB = matrixB.map(row => row.map(val => parseFloat(val) || 0));
    try {
      const resultMatrix = multiplyMatrices(numericA, numericB);
      // Round the result to 3 decimal places
      const roundedResult = resultMatrix.map(row => row.map(val => Number(val.toFixed(3))));
      setResult(roundedResult);
    } catch (error) {
      alert(error.message);
    }
  };

  const ResultMatrix = ({ matrix }) => (
    <div className="matrix-wrapper">
      {matrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              key={j}
              type="text"
              value={typeof value === 'number' ? value.toFixed(3) : value}
              readOnly
              className="matrix-cell result-input"
            />
          ))}
        </div>
      ))}
    </div>
  );

  return (
    <div className="matrix-multiplication">
      <h2>Matrix Multiplication Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={sizeA.rows}
            cols={sizeA.cols}
            matrix={matrixA}
            onChange={handleMatrixChange(setMatrixA)}
          />
          <div className="size-controls">
            <label>Size:</label>
            <input
              type="text"
              value={sizeA.rows}
              onChange={handleSizeChange(setSizeA, 'rows')}
              className="size-input"
            />
            <span>×</span>
            <input
              type="text"
              value={sizeA.cols}
              onChange={handleSizeChange(setSizeA, 'cols')}
              className="size-input"
            />
          </div>
        </div>
        <div className="operation">×</div>
        <div className="matrix-wrapper">
          <MatrixInput
            rows={sizeB.rows}
            cols={sizeB.cols}
            matrix={matrixB}
            onChange={handleMatrixChange(setMatrixB)}
          />
          <div className="size-controls">
            <label>Size:</label>
            <input
              type="text"
              value={sizeB.rows}
              onChange={handleSizeChange(setSizeB, 'rows')}
              className="size-input"
            />
            <span>×</span>
            <input
              type="text"
              value={sizeB.cols}
              onChange={handleSizeChange(setSizeB, 'cols')}
              className="size-input"
            />
          </div>
        </div>
      </div>
      <button onClick={calculateResult}>Calculate</button>
      {result.length > 0 && (
        <div className="result-container">
          <h2>Result</h2>
          <ResultMatrix matrix={result} />
        </div>
      )}
    </div>
  );
}

export default MatrixMultiplication;
