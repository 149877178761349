import React, { useState, useEffect } from 'react';
import * as math from 'mathjs';
import MatrixInput from './MatrixInput';
import './EigenvalueCalculator.css';

function EigenvalueCalculator() {
  const [size, setSize] = useState(2);
  const [matrix, setMatrix] = useState(() => Array(size).fill().map(() => Array(size).fill('')));
  const [eigenvalues, setEigenvalues] = useState(null);

  console.log('Render: Current state', { size, matrix, eigenvalues });

  const resizeMatrix = (matrix, newSize) => {
    console.log('Resizing matrix', { oldSize: matrix.length, newSize });
    const newMatrix = Array(newSize).fill().map(() => Array(newSize).fill(''));
    for (let i = 0; i < Math.min(matrix.length, newSize); i++) {
      for (let j = 0; j < Math.min(matrix[i].length, newSize); j++) {
        newMatrix[i][j] = matrix[i][j];
      }
    }
    return newMatrix;
  };

  useEffect(() => {
    console.log('Size changed, resizing matrix');
    setMatrix(prevMatrix => resizeMatrix(prevMatrix, size));
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    console.log('Matrix change', { i, j, value });
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    console.log('Size input', { value });
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 2 : Math.min(10, Math.max(2, parseInt(value)));
      console.log('Setting new size', { numValue });
      setSize(numValue);
    }
  };

  const calculateEigenvalues = () => {
    console.log('Calculating eigenvalues', { matrix });
    try {
      const mathMatrix = math.matrix(matrix.map(row => row.map(val => parseFloat(val) || 0)));
      console.log('Math.js matrix', { mathMatrix: mathMatrix.toArray() });
      const { values } = math.eigs(mathMatrix);
      console.log('Raw Eigenvalues', { values });
      
      // Convert DenseMatrix to array and format eigenvalues with increased precision
      const formattedEigenvalues = Array.from(values._data).map(v => {
        if (typeof v === 'number') {
          return `${v.toFixed(10)} + 0.0000000000i`;
        } else if (v.hasOwnProperty('re') && v.hasOwnProperty('im')) {
          return `${v.re.toFixed(10)} ${v.im >= 0 ? '+' : '-'} ${Math.abs(v.im).toFixed(10)}i`;
        } else {
          console.error('Unexpected eigenvalue format:', v);
          return 'Error: Invalid format';
        }
      });
      
      console.log('Formatted eigenvalues', { formattedEigenvalues });
      setEigenvalues(formattedEigenvalues);
    } catch (error) {
      console.error('Error calculating eigenvalues', { error });
      setEigenvalues(null);
      alert("An error occurred while calculating the eigenvalues: " + error.message);
    }
  };

  console.log('Before render, eigenvalues:', eigenvalues);

  return (
    <div className="eigenvalue-calculator">
      <h2>Eigenvalue Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size}
            cols={size}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="size-input"
        />
      </div>
      <button onClick={calculateEigenvalues}>Calculate Eigenvalues</button>
      {eigenvalues !== null && (
        <div className="result-container">
          <h2>Result</h2>
          <p>The eigenvalues are:</p>
          <table className="eigenvalue-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Eigenvalue</th>
              </tr>
            </thead>
            <tbody>
              {eigenvalues.map((value, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="eigenvalue-result">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EigenvalueCalculator;
