import React, { useState, useEffect } from 'react';
import * as math from 'mathjs';
import MatrixInput from './MatrixInput';
import './MatrixTranspose.css';

function MatrixTranspose() {
  const [size, setSize] = useState({ rows: 2, cols: 2 });
  const [matrix, setMatrix] = useState(() => Array(size.rows).fill().map(() => Array(size.cols).fill('')));
  const [transposedMatrix, setTransposedMatrix] = useState(null);

  const resizeMatrix = (matrix, rows, cols) => {
    const newMatrix = Array(rows).fill().map(() => Array(cols).fill(''));
    for (let i = 0; i < Math.min(matrix.length, rows); i++) {
      for (let j = 0; j < Math.min(matrix[i].length, cols); j++) {
        newMatrix[i][j] = matrix[i][j];
      }
    }
    return newMatrix;
  };

  useEffect(() => {
    setMatrix(prevMatrix => resizeMatrix(prevMatrix, size.rows, size.cols));
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (dimension) => (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 1 : Math.min(10, Math.max(1, parseInt(value)));
      setSize(prev => ({ ...prev, [dimension]: numValue }));
    }
  };

  const calculateTranspose = () => {
    try {
      const mathMatrix = math.matrix(matrix.map(row => row.map(val => parseFloat(val) || 0)));
      const transposed = math.transpose(mathMatrix);
      setTransposedMatrix(transposed.toArray());
    } catch (error) {
      setTransposedMatrix(null);
      alert("An error occurred while calculating the transpose: " + error.message);
    }
  };

  return (
    <div className="matrix-transpose">
      <h2>Matrix Transpose Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <h3>Input Matrix</h3>
          <MatrixInput
            rows={size.rows}
            cols={size.cols}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
          <div className="size-controls">
            <label>Size:</label>
            <input
              type="text"
              value={size.rows}
              onChange={handleSizeChange('rows')}
              className="size-input"
            />
            <span>×</span>
            <input
              type="text"
              value={size.cols}
              onChange={handleSizeChange('cols')}
              className="size-input"
            />
          </div>
        </div>
        {transposedMatrix && (
          <div className="matrix-wrapper">
            <h3>Transposed Matrix</h3>
            <MatrixInput
              rows={size.cols}
              cols={size.rows}
              matrix={transposedMatrix}
              readOnly={true}
            />
          </div>
        )}
      </div>
      <button onClick={calculateTranspose}>Calculate Transpose</button>
    </div>
  );
}

export default MatrixTranspose;
