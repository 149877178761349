import React from 'react';

const MatrixInput = ({ rows, cols, onChange, matrix }) => {
  // Ensure matrix is always a 2D array of the correct size
  const safeMatrix = Array(rows).fill().map((_, i) => 
    Array(cols).fill().map((_, j) => 
      matrix && matrix[i] && matrix[i][j] !== undefined ? matrix[i][j] : ''
    )
  );

  const handleInputChange = (e, i, j) => {
    onChange(e, i, j);
  };

  return (
    <div className="matrix-input">
      {safeMatrix.map((row, i) => (
        <div key={i}>
          {row.map((value, j) => (
            <input
              type="number"
              key={`${i}-${j}`}
              value={value}
              onChange={(e) => handleInputChange(e, i, j)}
              className="matrix-cell"
              step="any"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default MatrixInput;
