import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './TopMenu.css';

function TopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const menuItems = [
    {
      category: "Matrix Operations",
      items: [
        { name: "Matrix Addition/Subtraction", path: "/matrix-add-subtract" },
        { name: "Matrix Multiplication", path: "/matrix-multiplication" },
        { name: "Matrix Transpose", path: "/matrix-transpose" },
      ]
    },
    {
      category: "Matrix Properties",
      items: [
        { name: "Matrix Determinant", path: "/matrix-determinant" },
        { name: "Matrix Rank", path: "/matrix-rank" },
        { name: "Matrix Inverse", path: "/matrix-inverse" },
        { name: "Eigenvalue Calculator", path: "/eigenvalue-calculator" }, // Add this line
      ]
    },
    {
      category: "Matrix Decomposition",
      items: [
        { name: "LU Decomposition", path: "/lu-decomposition" },
        { name: "QR Factorization", path: "/qr-factorization" },
      ]
    },
  ];

  return (
    <div className="menu-container">
      <div className="top-bar">
        <button className="menu-toggle" onClick={toggleMenu}>
          {isOpen ? '×' : '☰'}
        </button>
        <div className="title-container">
          <Link to="/" className="site-title">MathSucks.lol</Link>
        </div>
      </div>
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul className="menu-categories">
          {menuItems.map(({ category, items }) => (
            <li key={category} className="menu-category">
              <button onClick={() => toggleCategory(category)} className="category-toggle">
                {category} {expandedCategories[category] ? '▼' : '▶'}
              </button>
              <ul className={`submenu ${expandedCategories[category] ? 'open' : ''}`}>
                {items.map(item => (
                  <li key={item.name}>
                    <Link to={item.path} onClick={toggleMenu}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </div>
  );
}

export default TopMenu;