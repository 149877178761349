export const multiplyMatrices = (A, B) => {
  if (!Array.isArray(A) || !Array.isArray(B) || !Array.isArray(A[0]) || !Array.isArray(B[0])) {
    throw new Error('Invalid matrix format');
  }

  const rowsA = A.length, colsA = A[0].length;
  const rowsB = B.length, colsB = B[0].length;

  if (colsA !== rowsB) {
    throw new Error('Matrix A columns must match Matrix B rows.');
  }

  let C = new Array(rowsA);
  for (let i = 0; i < rowsA; i++) {
    C[i] = new Array(colsB).fill(0);
    for (let j = 0; j < colsB; j++) {
      for (let k = 0; k < colsA; k++) {
        C[i][j] += A[i][k] * B[k][j];
      }
    }
  }
  return C;
};

export function addMatrices(matrixA, matrixB) {
  if (matrixA.length !== matrixB.length || matrixA[0].length !== matrixB[0].length) {
    throw new Error("Matrices must have the same dimensions for addition.");
  }
  return matrixA.map((row, i) => row.map((val, j) => val + matrixB[i][j]));
}

export function subtractMatrices(matrixA, matrixB) {
  if (matrixA.length !== matrixB.length || matrixA[0].length !== matrixB[0].length) {
    throw new Error("Matrices must have the same dimensions for subtraction.");
  }
  return matrixA.map((row, i) => row.map((val, j) => val - matrixB[i][j]));
}
