import React, { useState, useEffect } from 'react';
import * as math from 'mathjs';
import MatrixInput from './MatrixInput';
import './MatrixRank.css';

function MatrixRank() {
  const [size, setSize] = useState({ rows: 2, cols: 2 });
  const [matrix, setMatrix] = useState(() => Array(size.rows).fill().map(() => Array(size.cols).fill('')));
  const [rank, setRank] = useState(null);

  const resizeMatrix = (matrix, rows, cols) => {
    const newMatrix = Array(rows).fill().map(() => Array(cols).fill(''));
    for (let i = 0; i < Math.min(matrix.length, rows); i++) {
      for (let j = 0; j < Math.min(matrix[i].length, cols); j++) {
        newMatrix[i][j] = matrix[i][j];
      }
    }
    return newMatrix;
  };

  useEffect(() => {
    setMatrix(prevMatrix => resizeMatrix(prevMatrix, size.rows, size.cols));
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (dimension) => (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 1 : Math.min(10, Math.max(1, parseInt(value)));
      setSize(prev => ({ ...prev, [dimension]: numValue }));
    }
  };

  const calculateRank = () => {
    try {
      const mathMatrix = math.matrix(matrix.map(row => row.map(val => parseFloat(val) || 0)));
      
      // Use QR decomposition to calculate rank
      const qr = math.qr(mathMatrix);
      const R = qr.R;
      const epsilon = 1e-10; // Threshold for considering a value as zero
      let rank = 0;
      
      // Count non-zero diagonal elements of R
      for (let i = 0; i < Math.min(R.size()[0], R.size()[1]); i++) {
        if (Math.abs(R.get([i, i])) > epsilon) {
          rank++;
        } else {
          break; // Stop counting when we hit a zero (or near-zero) diagonal element
        }
      }
      
      setRank(rank);
    } catch (error) {
      setRank(null);
      alert("An error occurred while calculating the rank: " + error.message);
    }
  };

  return (
    <div className="matrix-rank">
      <h2>Matrix Rank Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size.rows}
            cols={size.cols}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
          <div className="size-controls">
            <label>Size:</label>
            <input
              type="text"
              value={size.rows}
              onChange={handleSizeChange('rows')}
              className="size-input"
            />
            <span>×</span>
            <input
              type="text"
              value={size.cols}
              onChange={handleSizeChange('cols')}
              className="size-input"
            />
          </div>
        </div>
      </div>
      <button onClick={calculateRank}>Calculate Rank</button>
      {rank !== null && (
        <div className="result-container">
          <h2>Result</h2>
          <p>The rank of the matrix is: {rank}</p>
        </div>
      )}
    </div>
  );
}

export default MatrixRank;
