import React, { useState, useEffect } from 'react';
import * as math from 'mathjs';
import MatrixInput from './MatrixInput';
import './MatrixDeterminant.css';

function MatrixDeterminant() {
  const [size, setSize] = useState(2);
  const [matrix, setMatrix] = useState(() => Array(size).fill().map(() => Array(size).fill('')));
  const [determinant, setDeterminant] = useState(null);

  useEffect(() => {
    setMatrix(prevMatrix => {
      const newMatrix = Array(size).fill().map(() => Array(size).fill(''));
      for (let i = 0; i < Math.min(prevMatrix.length, size); i++) {
        for (let j = 0; j < Math.min(prevMatrix[i].length, size); j++) {
          newMatrix[i][j] = prevMatrix[i][j];
        }
      }
      return newMatrix;
    });
  }, [size]);

  const handleMatrixChange = (e, i, j) => {
    const value = e.target.value;
    setMatrix(prevMatrix => {
      const newMatrix = prevMatrix.map(row => [...row]);
      newMatrix[i][j] = value;
      return newMatrix;
    });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      const numValue = value === '' ? 2 : Math.min(10, Math.max(2, parseInt(value)));
      setSize(numValue);
    }
  };

  const calculateDeterminant = () => {
    try {
      const mathMatrix = math.matrix(matrix.map(row => row.map(val => parseFloat(val) || 0)));
      const det = math.det(mathMatrix);
      setDeterminant(Number(det.toFixed(6)));
    } catch (error) {
      setDeterminant(null);
      alert("An error occurred while calculating the determinant: " + error.message);
    }
  };

  return (
    <div className="matrix-determinant">
      <h2>Matrix Determinant Calculator</h2>
      <div className="matrix-container">
        <div className="matrix-wrapper">
          <MatrixInput
            rows={size}
            cols={size}
            matrix={matrix}
            onChange={handleMatrixChange}
          />
        </div>
      </div>
      <div className="size-controls">
        <label>Size:</label>
        <input
          type="text"
          value={size}
          onChange={handleSizeChange}
          className="size-input"
        />
      </div>
      <button onClick={calculateDeterminant}>Calculate Determinant</button>
      {determinant !== null && (
        <div className="result-container">
          <h2>Result</h2>
          <p>The determinant is: {determinant}</p>
        </div>
      )}
    </div>
  );
}

export default MatrixDeterminant;
