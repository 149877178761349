import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <Helmet>
        <title>MathSucks.lol - Home | Free Matrix Calculators</title>
        <meta name="description" content="Welcome to MathSucks.lol. Access free online matrix calculators for various operations. Make complex matrix calculations easy and quick." />
      </Helmet>
      <header className="hero">
        <h1>MathSucks.lol</h1>
        <p>Making math suck less, one calculation at a time.</p>
      </header>

      <section className="features">
        <h2>Our Calculators</h2>
        <div className="calculator-grid">
          <div className="calculator-card">
            <h3>Matrix Multiplication</h3>
            <p>Multiply matrices with ease.</p>
            <Link to="/matrix-multiplication" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>Matrix Addition/Subtraction</h3>
            <p>Add or subtract matrices.</p>
            <Link to="/matrix-add-subtract" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>Matrix Transpose</h3>
            <p>Find the transpose of a matrix.</p>
            <Link to="/matrix-transpose" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>Matrix Inverse</h3>
            <p>Calculate the inverse of a matrix.</p>
            <Link to="/matrix-inverse" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>Matrix Determinant</h3>
            <p>Compute the determinant of a matrix.</p>
            <Link to="/matrix-determinant" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>Matrix Rank</h3>
            <p>Find the rank of a matrix.</p>
            <Link to="/matrix-rank" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>LU Decomposition</h3>
            <p>Perform LU decomposition on a matrix.</p>
            <Link to="/lu-decomposition" className="btn">Try it</Link>
          </div>
          <div className="calculator-card">
            <h3>QR Factorization</h3>
            <p>Perform QR factorization on a matrix.</p>
            <Link to="/qr-factorization" className="btn">Try it</Link>
          </div>
          {/* Add the new Eigenvalue Calculator card */}
          <div className="calculator-card">
            <h3>Eigenvalue Calculator</h3>
            <p>Calculate the eigenvalues of a matrix.</p>
            <Link to="/eigenvalue-calculator" className="btn">Try it</Link>
          </div>
        </div>
      </section>

      <section className="about">
        <h2>Why MathSucks.lol?</h2>
        <ul>
          <li>Simple, user-friendly interface</li>
          <li>Fast and accurate calculations</li>
          <li>Free to use, no sign-up required</li>
          <li>Constantly adding new calculators</li>
        </ul>
      </section>

      <footer className="wwyb">
        <em>WWYB</em>
      </footer>
    </div>
  );
}

export default Home;
